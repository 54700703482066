<template>
    <transition name="fade">
        <div class="popup-modal" v-if="isVisible">
            <div class="window">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
	name: "PopupModal",
	data() {
		return {
			isVisible: false
        };
	},
	props: [],
	methods: {
		open ( ){
			this.isVisible = true;
        },
        close ( ) {
			this.isVisible = false;
        }
    }
};
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

</style>