<template>
    <PopupModal ref="popup">
        <h2>{{title}}</h2>
        <p>{{message}}</p>
        <div class="buttons">
            <button class="button button--small button--secondary" @click="_cancel">{{cancelButton}}</button>
            <button class="button button--small button--primary" @click="_confirm">{{okButton}}</button>
        </div>
    </PopupModal>
</template>

<script>
import PopupModal from './PopupModal';
export default {
	name: "ConfirmDialog",
    components: {
		PopupModal
    },
	data() {
		return {
			title: undefined,
            message: undefined,
            okButton: undefined,
            cancelButton: 'Storno',

            resolvePromise: undefined,
            rejectPromise: undefined
        };
	},
	props: [],
	methods: {
		show( opts = {} ) {
			this.title = opts.title;
			this.message = opts.message;
			this.okButton = opts.okButton;

			if( opts.cancelButton )
				this.cancelButton = opts.cancelButton;

			this.$refs.popup.open();

			return new Promise((resolve,reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
            })
        },
        _confirm () {
			this.$refs.popup.close();
			this.resolvePromise(true);
        },
        _cancel ( ) {
			this.$refs.popup.close();
			this.resolvePromise(false)
        }
    }
};
</script>

<style scoped>
.buttons{
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
}
</style>